'use client';

import logOutImage from '@/assets/images/log-out.png';
import Button from '@/components/shared/button/Button';
import dynamic from 'next/dynamic';
import Image from 'next/image';

const Modal = dynamic(() => import('@/components/shared/modal'), { ssr: false });

interface sessionActionButtonProps {
  continueSessionText: string;
  onKeepWorking: React.MouseEventHandler<HTMLButtonElement> | undefined;
  logOutText: string;
  onLogOut: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

interface sessionNoticeProps {
  isOpen: boolean;
  onKeepWorking: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onLogOut: React.MouseEventHandler<HTMLButtonElement> | undefined;
  localeStrings: SessionLocaleStringsType;
}

export type SessionLocaleStringsType = {
  logOutText: string;
  noticeHeader: string;
  noticeText: string;
  continueSessionText: string;
};

function SessionActionButtons({
  continueSessionText,
  onKeepWorking,
  logOutText,
  onLogOut
}: sessionActionButtonProps): JSX.Element {
  return (
    <>
      <div>
        <Button appearance="filled" text={continueSessionText} onClick={onKeepWorking} />
      </div>
      <div>
        <Button appearance="ghost" text={logOutText} onClick={onLogOut} />
      </div>
    </>
  );
}

export function SessionNotice({ isOpen, onKeepWorking, onLogOut, localeStrings }: sessionNoticeProps): JSX.Element {
  return isOpen ? (
    <Modal
      isOpen={isOpen}
      zIndex={1600}
      className="bg-white shadow inline-flex !max-h-[612px] !w-[560px] flex-col items-center justify-center rounded-xl p-6"
    >
      <div className="inline-flex w-full flex-col items-center justify-center gap-3 text-center">
        <Image src={logOutImage.src} alt="Log Out Illustration" width={350} height={350} />
        <div className="text-d-sm/loose font-normal text-neutral-90">{localeStrings.noticeHeader}</div>
        <div className="pb-4 text-b-base/none font-normal text-neutral-60">{localeStrings.noticeText}</div>

        <SessionActionButtons
          continueSessionText={localeStrings.continueSessionText}
          onKeepWorking={onKeepWorking}
          logOutText={localeStrings.logOutText}
          onLogOut={onLogOut}
        />
      </div>
    </Modal>
  ) : (
    <></>
  );
}
