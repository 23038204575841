'use client';

import { ImageProps } from 'next/image';
import Accordion from '@/components/legacy/header/Accordion';
import useShoppingCart from '@/hooks/useShoppingCart';
import { formatDate, formatMessage } from '@/utils/translationHelper';
import type { Locale } from '@/i18n';
import VenueLocation from '@/components/legacy/header/cart/VenueLocation';
import StatusTag from '@/components/legacy/header/cart/StatusTag';
import Icon from '@/components/shared/Icons';
import { useCallback, useMemo } from 'react';
import { MANAGE_VENUES_URL, getStatusTagStrings } from '@/utils/shoppingCartHelper';
import placeholder from '@/assets/images/shopping-cart-placeholder.png';
import { rfpStatus } from '@/constants/rfpStatus';
import ImageComponent from '@/components/ImageComponent';
import type { ResultsPageAnalyticsParams } from '@/models/analyticsParams/ResultsPageAnalyticsParams';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from '@/lib/router-events/patch-router/router';
import { updateVenueUrl } from '@/utils/urlHelper';
import useSpinner from '@/hooks/useSpinner';
import { getVenueProfileUrlForShoppingCart, isVenueProfileSelected } from '@/utils/venueDetailsHelper';
import { useAppFeatures } from '@/hooks/useAppFeatures';

type Props = {
  strings: Record<string, string>;
  testID?: string;
  locale: Locale;
  rfpId: string;
  cventAppDomain: string;
  hubCategory: string | undefined;
  isLoggedIn: boolean;
  isV2ProfileEnabledForAccount: boolean;
};

type VenueListProps = {
  strings: Record<string, string>;
  testID?: string;
  locale: Locale;
  hubCategory: string | undefined;
  isLoggedIn: boolean;
  isV2ProfileEnabledForAccount: boolean;
};

/*
 * Renders a specific section for the RFP Context
 * that contains a list of venues already added to the RFP from Normandy/Phoneix
 */
export default function VenuesInRfpList({
  strings,
  locale,
  rfpId,
  cventAppDomain,
  testID,
  hubCategory,
  isLoggedIn,
  isV2ProfileEnabledForAccount
}: Props): JSX.Element {
  const { rfpVenueList, isRfpListCollapsed, setIsRfpListCollapsed } = useShoppingCart();
  const title = formatMessage(strings.venue_already_received_rfp, locale, {
    venues: rfpVenueList.length
  });
  const maxHeight = useMemo(() => {
    if (rfpVenueList?.length > 0 && !isRfpListCollapsed) {
      return 'max-h-[100px] md:max-h-[21vh]';
    }
    return 'max-h-[230px] md:max-h-[46vh]';
  }, [rfpVenueList, isRfpListCollapsed]);

  return (
    <>
      <div className="h-3 w-full bg-[#eeeff0]" />
      <div className="relative flex min-w-0 text-b-md font-normal">
        <Accordion
          accordionText={title}
          accordionStyles={{
            contentClassName: '',
            accordionTextClassName: 'pl-2',
            accordionIconClassName: 'h-10 w-10 pl-4 fill-[#bcbdbf]'
          }}
          isAccordionCollapsed={isRfpListCollapsed}
          setIsAccordionCollapsed={setIsRfpListCollapsed}
          testID="shopping-cart-accordion"
        >
          <div className={`${maxHeight} overflow-y-auto px-5 py-2.5`}>
            <VenueList
              strings={strings}
              locale={locale}
              testID={testID}
              hubCategory={hubCategory}
              isLoggedIn={isLoggedIn}
              isV2ProfileEnabledForAccount={isV2ProfileEnabledForAccount}
            />
          </div>
          <a
            className="flex bg-[#e9edef] px-5 pb-1 pt-2.5 text-[#006AE1] hover:text-[#0052ae] hover:no-underline"
            href={`${cventAppDomain}${MANAGE_VENUES_URL}&rfpstub=${rfpId}`}
          >
            {strings.view_all_venues}
            <span className="absolute right-5">
              <Icon
                id="chevron-right"
                className="h-6 w-6 fill-[#006AE1] hover:fill-[#0052ae]"
                aria-label={strings.view_all_venues}
              />
            </span>
          </a>
        </Accordion>
      </div>
    </>
  );
}

function VenueList({
  strings,
  locale,
  testID,
  hubCategory,
  isLoggedIn,
  isV2ProfileEnabledForAccount
}: VenueListProps): JSX.Element {
  const { rfpVenueList, setIsOpen } = useShoppingCart();
  const statusTagStrings: Record<string, string> = useMemo(() => getStatusTagStrings(strings), [strings]);
  const searchParams = useSearchParams();
  const { setShowSpinner } = useSpinner();
  const router = useRouter();
  const pathName = usePathname();
  const { v2Profile, v2ProfileLoggedIn } = useAppFeatures();
  const isV2ProfileActive = isLoggedIn ? v2ProfileLoggedIn && isV2ProfileEnabledForAccount : v2Profile;
  const handleClick = useCallback(
    (e, venue) => {
      e.preventDefault();
      const resultsPageAnalyticsParams: ResultsPageAnalyticsParams = {
        searchTerm: venue.searchTerm
      };
      const venueUrl = updateVenueUrl(venue.url, searchParams, resultsPageAnalyticsParams, hubCategory);
      // handles command + click event
      if (e.metaKey) {
        window.open(venueUrl);
      } else if (isV2ProfileActive) {
        if (!isVenueProfileSelected(venue, locale, pathName)) {
          router.push(getVenueProfileUrlForShoppingCart(venue, locale, searchParams));
        }
        setIsOpen(false);
      } else {
        setShowSpinner(true);
        window.open(venueUrl, '_self');
      }
    },
    [hubCategory, searchParams, setShowSpinner, router, setIsOpen, isV2ProfileActive, locale, pathName]
  );
  const venueList = rfpVenueList.map(venue => {
    const venueType = venue.type ? strings[`_venue_type_${venue.type.toLowerCase()}`] : null;
    const location = (
      <VenueLocation
        city={venue.city}
        stateCode={venue.stateProvinceCode ?? ''}
        countryCode={venue.countryCode}
        dataCventId={`${testID}-venue-location`}
      />
    );

    const imageProps: ImageProps = {
      fill: true,
      sizes: '(max-width: 640px) 100vw, 25vw',
      style: { objectFit: 'cover', width: '100%', borderRadius: '10px' },
      src: venue.ofrgImage ?? placeholder.src,
      alt: strings.csn_search_image_fallbackText
    };
    const venueOutput = (
      <div className="grid grid-cols-5">
        <div className="col-span-2 aspect-[1.638] w-full">
          <div className="relative h-full w-full">
            <ImageComponent imageProps={imageProps} />
          </div>
        </div>
        <div className="col-span-3 ml-2.5 leading-[1.2]">
          <h5
            className="mb-[5px] line-clamp-2 overflow-hidden text-ellipsis text-b-xs font-normal"
            title={venue.name}
            data-cvent-id={`${testID}-venue-name`}
          >
            {venue.name}
          </h5>
          <div className="mb-2.5 text-b-xs font-[300]">
            <div data-cvent-id={`${testID}-venue-location`}>{location}</div>
            {venueType && <div data-cvent-id={`${testID}-venue-location-type`}>{venueType}</div>}
          </div>
          <StatusTag status={venue.rfpStatus ?? ''} strings={statusTagStrings} />
          {venue.rfpSentDate && venue.rfpStatus !== rfpStatus.NOT_SENT && (
            <div className="text-[11px]">
              {formatMessage(strings.rfp_sent_on, locale, {
                date: formatDate(locale, venue.rfpSentDate)
              })}
            </div>
          )}
        </div>
      </div>
    );
    return (
      <li
        className="border-t border-solid border-t-[#D9DCDE] first:border-t-0 hover:border-t-[#eeeef0]"
        key={venue.venueId}
      >
        <a
          href={venue.url}
          className="block py-2.5 pl-2 pr-5 text-[#1A2026] hover:bg-[#eeeff0] hover:text-[#1A2026] hover:no-underline focus:bg-[#eeeff0] focus:text-[#1A2026]"
          onClick={e => handleClick(e, venue)}
        >
          {venueOutput}
        </a>
      </li>
    );
  });

  return (
    <div className="ml-auto mr-auto px-2.5">
      <ul className="-mx-5 -my-2.5 list-none p-2.5">{venueList}</ul>
    </div>
  );
}
